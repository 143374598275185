<div
  class="slice"
  [ngClass]="[
    sliceData.desktop_columns ? sliceData.desktop_columns : 'four',
    sliceData.margin_bottom ? sliceData.margin_bottom : 'small-margin-bottom',
    sliceData.margin_top ? sliceData.margin_top : 'small-margin-top'
  ]"
  id="{{ sliceData.id }}"
>
  <h2 *ngIf="sliceData.title" class="content-title">{{ sliceData.title }}</h2>
  <div class="content four-items-container">
    <ng-container *ngFor="let item of sliceData.items">
      <div class="item-container">
        <img [src]="item.icon" alt="" width="33" height="33" />
        <p class="item-title">{{ item.title }}</p>
        <p class="item-subtitle">{{ item.subtitle }}</p>
      </div>
    </ng-container>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userData: any;
  loading = true;
  activeStep: number;
  userSubscriptions: {}[];
  showSubscriptions = false;
  cognitoId: string;
  suspendedAccount = false;
  suspensionDate: string;
  currentLang: string;
  addSubscriptionModal = false;
  userEmail: string;
  userIsOnboarded: boolean | string = 'loading';

  constructor(
    private webAppService: WebAppService,
    private amplifyService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private translate: TranslateService,
    public lang: LanguageService
  ) {}

  hideSubscriptionModal(event): void {
    this.addSubscriptionModal = event;
  }

  isSubscriptionActive(account) {
    return [
      'subscriptionActiveUntil',
      'talkActiveUntil',
      'internationalActiveUntil'
    ]
      .map(subscriptionName => {
        return account[subscriptionName] &&
          new Date(account[subscriptionName]) > new Date()
          ? 'active'
          : 'inactive';
      })
      .filter(status => status === 'active').length;
  }

  async blockUnblockSim(event) {
    this.loading = true;
    (
      await this.webAppService.webWebBlockUnblockSimCardPost({
        killbillId: event.subscription.id,
        status: event.operation
      })
    ).subscribe(
      async data => {
        this.notifier.notify(
          'success',
          this.translate.instant('OPERATION_SUCCESS')
        );
        const interval = setInterval(async () => {
          await this.getUserSubscriptions();

          this.userSubscriptions.map((subscription: any) => {
            if (subscription.number === event.subscription.number) {
              if (subscription.blockedSim !== event.subscription.blockedSim) {
                clearInterval(interval);
                window.location.reload();
              }
            }
          });
        }, 4000);
      },
      error => {
        this.notifier.notify(
          'error',
          this.translate.instant('OPERATION_FAILED')
        );
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
    );
  }

  async getUserData() {
    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      this.notifier.notify(
        'error',
        this.translate.instant('PASSWORD_PROCESS_RESET_NOT_STARTED')
      );
      setTimeout(() => {
        const lang = this.route.snapshot.params[`lang`];
        this.router.navigate(['/' + lang + '/sign-in']);
      }, 800);
    }

    this.cognitoId = user.username;
    this.userEmail = user.attributes.email?.split('@')[0];

    (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).subscribe(
      (data: any) => {
        this.userData = data;

        if (this.userData.data.gdpr_request_time) {
          this.suspensionDate = this.userData.data.gdpr_request_time;
          this.suspendedAccount = true;
          return;
        }

        data.data.__ON_BOARDING_STEP === 'ON_BOARDED'
          ? (this.userIsOnboarded = true)
          : (this.userIsOnboarded = false);
      },
      async error => {
        console.log(error);
        console.log(error.status);
      }
    );
  }

  numberWithSpaces(x) {
    const n = x.toString();
    return (
      n[0] +
      n[1] +
      n[2] +
      ' ' +
      n[3] +
      n[4] +
      n[5] +
      ' ' +
      n[6] +
      n[7] +
      ' ' +
      n[8] +
      n[9]
    );
  }

  async getUserSubscriptions() {
    (
      await this.webAppService.webWebGetChildAccountsGet(this.cognitoId, true)
    ).subscribe(data => {
      // @ts-ignore
      data.data.data.length >= 1 ? (this.showSubscriptions = true) : null;
      // @ts-ignore
      this.userSubscriptions = data.data.data
        .map(account => {
          if (!account.name) {
            return false;
          }

          if (account.name === 'phone number') {
            return false;
          }

          if (account.portInStatus && account.portInStatus !== 'COMPLETE') {
            return false;
          }

          let blockedSim: Boolean | string = false;

          if (account.blockedSim === 'active') {
            blockedSim = true;
          }

          if (
            account.numberNotYetActivated &&
            account.numberNotYetActivated === 'true'
          ) {
            blockedSim = 'notActivated';
          }

          return {
            number: this.numberWithSpaces(account.name),
            status: 'active',
            subscription: this.isSubscriptionActive(account)
              ? 'active'
              : 'inactive',
            // fiveG:
            //   account.subscriptions.filter(
            //     subscription => subscription.planName === 'swype-5g'
            //   ).length ||
            //   (account.fiveGActiveUntil &&
            //     new Date(account.fiveGActiveUntil) > new Date())
            //     ? 'active'
            //     : 'inactive',
            //showDetails: false,
            id: account.accountId,
            internationalId: account.internationalAccountId,
            blockedSim: blockedSim
          };
        })
        .filter(object => !!object);
      //this.loading = false;
    });
  }

  async signOut() {
    await this.amplifyService.signOut();
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  async ngOnInit(): Promise<void> {
    this.lang = this.route.snapshot.params[`lang`];

    await this.getUserData();
    await this.getUserSubscriptions();
    this.loading = false;
  }
}

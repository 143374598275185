import { Component, OnInit } from '@angular/core';
import { OpenAppStoreService } from '../open-app-store.service';

@Component({
  selector: 'app-store-buttons',
  templateUrl: './store-buttons.component.html',
  styleUrls: ['./store-buttons.component.scss']
})
export class StoreButtonsComponent implements OnInit {
  constructor(public storeService: OpenAppStoreService) {}

  ngOnInit(): void {}
}

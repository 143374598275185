<app-toasts></app-toasts>
<app-splash
  (onDone)="hideSplash = true"
  *ngIf="!hideSplash && showSplash"
></app-splash>

<div
  [@routeAnimations]="prepareRoute(outlet)"
  class="router-container"
  [ngClass]="{ hidden: hideRouter, 'add-background': !hideRouter }"
>
  <app-cookie-banner></app-cookie-banner>
  <router-outlet #outlet="outlet"></router-outlet>
</div>

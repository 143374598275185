import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ForgotPassword } from '../DTO/ForgotPassword';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { TranslateService } from '@ngx-translate/core';
import { PasswordRequirements } from '../requirements.interface';

@Component({
  selector: 'app-renew-password',
  templateUrl: './renew-password.component.html',
  styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  value: string;
  requirements: PasswordRequirements;
  password: string;
  confirmation: string;
  valid: boolean;
  loading = false;
  sibg: 'MINIMUM_EIGHT_CHARACTERS';

  model = new ForgotPassword('');

  constructor(
    private notifier: NotifierService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private amplify: AmplifyService,
    private translate: TranslateService
  ) {
    this.requirements = {
      minimumEightCharacters: false,
      oneLowerCaseLetter: false,
      oneUpperCaseLetter: false,
      atLeastOneDigit: false,
      oneSpecialSymbol: false
    };
    this.valid = false;
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      password: ['Default value']
      // passwordConfirm: ['Default value']
    });

    if (!localStorage.getItem('email') || !localStorage.getItem('code')) {
      this.showNotification(
        'error',
        this.translate.instant('PASSWORD_PROCESS_RESET_NOT_STARTED')
      );
      setTimeout(() => {
        const lang = this.route.snapshot.params[`lang`];
        this.router.navigate(['/' + lang + '/sign-in']);
      }, 1000);
    }
  }

  returnToSignIn() {
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  hasLowerCase(str) {
    return /[a-z]/.test(str);
  }

  hasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  hasAtLeastOneDigit(str) {
    return /\d/.test(str);
  }

  hasSymbol(str) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
  }

  checkValidation() {
    if (
      this.requirements.minimumEightCharacters &&
      this.requirements.oneLowerCaseLetter &&
      this.requirements.oneUpperCaseLetter &&
      this.requirements.atLeastOneDigit &&
      this.requirements.oneSpecialSymbol
    ) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  onInput(event) {
    const value = event.target.value;
    this.password = value;

    this.requirements.minimumEightCharacters = value.length > 7;
    this.requirements.oneLowerCaseLetter = this.hasLowerCase(value);
    this.requirements.oneUpperCaseLetter = this.hasUpperCase(value);
    this.requirements.atLeastOneDigit = this.hasAtLeastOneDigit(value);
    this.requirements.oneSpecialSymbol = this.hasSymbol(value);

    this.checkValidation();
  }

  onInputConfirmation(event) {
    const value = event.target.value;
    this.confirmation = value;
    this.checkValidation();
  }

  async submit(event) {
    this.loading = true;
    if (!this.valid) {
      this.loading = false;
      return;
    }

    const result = await this.amplify.finishRecovery(
      localStorage.getItem('email'),
      localStorage.getItem('code'),
      this.password
    );

    localStorage.removeItem('email');
    localStorage.removeItem('code');

    if (result === 'SUCCESS') {
      this.loading = false;
      this.valid = false;
      this.showNotification(
        'success',
        this.translate.instant('PASSWORD_CHANGED')
      );
      setTimeout(() => {
        this.router.navigate(['/en/sign-in']);
      }, 1000);
      return;
    }

    if (result.code === 'CodeMismatchException') {
      this.loading = false;
      this.showNotification(
        'error',
        this.translate.instant('INVALID_VERIFICATION_CODE')
      );
      const lang = this.route.snapshot.params[`lang`];
      setTimeout(() => {
        this.router.navigate(['/' + lang + '/forgot-password']);
      }, 1000);
      return;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ShowUrlParameterSlice } from '../slices/show-url-parameter-slice';
import { ToastsService } from '../toasts.service';
import { ToastsComponent } from '../toasts/toasts.component';

@Component({
  selector: 'app-show-url-parameter-slice',
  templateUrl: './show-url-parameter-slice.component.html',
  styleUrls: ['./show-url-parameter-slice.component.scss']
})
export class ShowUrlParameterSliceComponent implements OnInit {
  @Input() sliceData: ShowUrlParameterSlice;

  parameterValue: string;

  faCopy = faCopy;

  constructor(
    private route: ActivatedRoute,
    private toast: ToastsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(res => {
      this.parameterValue = res.get(this.sliceData.parameterName);
    });
  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod/i.test(ua);
  }

  writeToClipboard(code: string) {
    if (this.isMobile()) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          this.toast.add(
            this.translate.instant('CLIPBOARD_COPY_SUCCESS'),
            true,
            10 * 1000
          );
        })
        .catch(err => console.log('writing to clipboard failed: ', err));
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Toast } from './toast.class';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {
  observer;

  public toasts = new Observable(observer => {
    this.observer = observer;
  });

  constructor() {}

  public add(message: string, isGood: boolean = true, duration: number = 3000) {
    this.observer.next(new Toast(message, duration, isGood));
  }
}

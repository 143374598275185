<app-auth-container>
  <notifier-container></notifier-container>
  <div class="forgot-password-container">
    <div class="content-title">{{ "RESET_PASSWORD" | translate }}</div>
    <div class="content-description">
      <span>{{ "RESET_PASSWORD_DESCRIPTION" | translate }}</span>
    </div>
    <div class="form-container">
      <form [formGroup]="resetPasswordForm">
        <app-text-field
          formControlName="email"
          [value]="value"
          (keyup)="onEmailInput($event)"
          [disabled]="false"
          [placeholder]="'EMAIL' | translate"
          (keyup.enter)="onSubmit()"
        >
        </app-text-field>
      </form>
      <a (click)="onSubmit()" class="button-container">
        <button class="sign-in-button" [ngClass]="{ active: valid }">
          {{ "RESET_PASSWORD" | translate }}
        </button>
      </a>
      <div class="reset-password">
        <span>{{ "REMEMBER_PASSWORD" | translate }}</span>
        <button (click)="returnToSignIn()">
          {{ "LOGIN" | translate }}
          <img
            src="/assets/new/chevron_red.svg"
            class="arrow"
            alt="forgot password arrow"
          />
        </button>
      </div>
    </div>
  </div>
</app-auth-container>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

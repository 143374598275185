import { Component, OnInit, Input } from '@angular/core';
import { ImageWithCtaSlice } from '../slices/image-with-cta-slice';
import { OpenAppStoreService } from '../open-app-store.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-image-with-cta',
  templateUrl: './image-with-cta.component.html',
  styleUrls: ['./image-with-cta.component.scss']
})
export class ImageWithCTAComponent implements OnInit {
  @Input() sliceData: ImageWithCtaSlice;

  constructor(
    public storeService: OpenAppStoreService,
    public lang: LanguageService
  ) {}

  ngOnInit() {}
}

<div class="slice" id="tutorial">
  <div class="content center-content">
    <div class="tutorial-container">
      <div
        class="steps"
        *ngFor="let item of sliceData.items; let first = first; let i = index"
      >
        <div class="step-item" *ngIf="!first">
          <div class="step-order">{{ i + 1 }}.</div>
          <div class="step-content">
            <p class="step-title">{{ item.title }}</p>
            <p class="step-description">{{ item.description }}</p>
            <div class="step-features">
              <span *ngFor="let feature of item.features">
                <img class="check" src="/assets/new/homepoint.svg" alt="" />{{
                  feature
                }}</span
              >
            </div>
          </div>
        </div>

        <div class="step-item first" *ngIf="first">
          <div class="step-order">{{ i + 1 }}.</div>
          <div class="step-content">
            <p class="step-title">{{ item.title }}</p>
            <div class="step-description">
              <div>
                <span>{{ item.description }} </span>
                <br>
                <a (click)="storeService.openAppStore('apple')">Apple Store</a>
                {{ "TERMS3" | translate }}
                <a (click)="storeService.openAppStore('google')"
                  >Google Play Store</a
                >.
              </div>
            </div>
            <button class="sign-in-button store-button" *ngIf="!!isMobileDevice" (click)="openStore()">
              <!--needs to be replaced by new google store icon
                <img
                *ngIf="isAndroid"
                style="margin: 0px 6px 3px 0px;"
                src="/assets/new/google_white.svg"
                alt="google icon"
              />-->
              <img
                *ngIf="!isAndroid"
                style="margin: 0px 6px 6px 0px;"
                src="/assets/new/apple_white.svg"
                alt="apple icon"
              />{{ "TRYFORFREE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { TrackingService } from '../tracking.service';
import { Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';

@Component({
  selector: 'app-sign-up-button',
  templateUrl: './sign-up-button.component.html',
  styleUrls: ['./sign-up-button.component.scss']
})
export class SignUpButtonComponent implements OnInit {
  @Input() className: string;
  @Input() location: string;
  @Input() tariffName: string;

  constructor(
    public lang: LanguageService,
    public trackingService: TrackingService,
    private router: Router,
    private amplifyService: AmplifyService
  ) {}

  async navigateToSignUp() {
    if (this.location) {
      this.trackingService.getSwype(this.location);
    }
    if (this.tariffName) {
      this.trackingService.addToCart(this.tariffName);
    }

    (await this.amplifyService.getCurrentUser())
      ? await this.router.navigate([`/${this.lang.current}/dashboard`])
      : await this.router.navigate([`/${this.lang.current}/sign-up`]);
  }

  ngOnInit(): void {}
}

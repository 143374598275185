<app-auth-container>
  <notifier-container></notifier-container>
  <div class="sign-in-container">
    <div class="content-title">{{ "SIGN_IN_BTN" | translate }}</div>
    <div class="content-description">
      <span>{{ "NEW_TO_SWYPE" | translate }}</span>
      <span (click)="goToSignUp()"
        >{{ "CREATE_ACCOUNT" | translate }}
        <img src="/assets/new/chevron_red.svg"
      /></span>
    </div>
    <app-social-login *ngIf="!isFacebookInAppBrowser"></app-social-login>
    <br *ngIf="isFacebookInAppBrowser">
    <div class="form-container">
      <app-text-field
        [authentication]="true"
        [disabled]="false"
        (input)="onEmailInput($event)"
        [placeholder]="'EMAIL' | translate"
      >
      </app-text-field>
      <app-text-field
        [authentication]="true"
        type="password"
        (input)="onPasswordInput($event)"
        [disabled]="false"
        [placeholder]="'PASSWORD' | translate"
        (keyup.enter)="signIn($event)"
      >
      </app-text-field>
      <a (click)="signIn($event)" class="button-container">
        <button class="sign-in-button" [ngClass]="{ active: valid }">
          {{ "SIGN_IN_BTN" | translate }}
        </button>
      </a>
      <div class="forgot-password">
        <span>{{ "FORGOT_PASSWORD" | translate }}</span>
        <button (click)="returnToForgotPassword()">
          {{ "RESET" | translate }}
          <img
            src="/assets/new/chevron_red.svg"
            class="arrow"
            alt="forgot password arrow"
          />
        </button>
      </div>
    </div>
  </div>
</app-auth-container>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

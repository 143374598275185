import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LangaugeResolve } from './language.resolve';
import { LoadingComponent } from './loading/loading.component';
import { ImprintPageComponent } from './imprint-page/imprint-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PreviewRedirectGuard } from './preview-redirect.guard';
import { ReferrerTrackingResolve } from './referrer-tracking.resolve';
import { PrismicResolve } from './prismic.resolve';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { LanguageGuard } from './language.guard';
import { InfluencerTrackingResolve } from './influencer-tracking.resolve';
import { GdprResolve } from './gdpr.resolve';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CheckYourEmailComponent } from './check-your-email/check-your-email.component';

const routes: Routes = [
  { path: 'de/ds', redirectTo: 'de/data-protection' },
  { path: 'en/dp', redirectTo: 'en/data-protection' },
  { path: 'it/pd', redirectTo: 'it/data-protection' },
  { path: 'fr/dc', redirectTo: 'fr/data-protection' },
  { path: 'ds', redirectTo: 'de/data-protection' },
  { path: 'dp', redirectTo: 'en/data-protection' },
  { path: 'pd', redirectTo: 'it/data-protection' },
  { path: 'dc', redirectTo: 'fr/data-protection' },
  {
    path: ':lang',
    component: LandingPageComponent,
    resolve: {
      src: ReferrerTrackingResolve,
      prismicContent: PrismicResolve
    },
    canActivate: [LanguageGuard]
  },
  {
    path: ':lang/sign-in',
    component: SignInComponent
  },
  {
    path: ':lang/sign-up',
    component: SignUpComponent
  },
  {
    path: ':lang/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: ':lang/check-your-email',
    component: CheckYourEmailComponent
  },
  {
    path: ':lang/email-verification/:code',
    component: EmailVerificationComponent
  },
  {
    path: ':lang/email-verification',
    component: EmailVerificationComponent
  },
  {
    path: ':lang/renew-password',
    component: RenewPasswordComponent
  },
  {
    path: ':lang/dashboard',
    component: DashboardComponent
  },
  {
    path: ':lang/download-app',
    component: DownloadAppComponent
  },
  {
    path: ':lang/imprint',
    component: ImprintPageComponent,
    data: { animation: 'ImprintPage' }
  },
  // GDPR redirects
  {
    path: ':lang/ms/gdpr',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: ':lang/gdpr',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: 'gdpr',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: ':lang/unsubscribe',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: 'unsubscribe',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: ':lang/unsubscribe/:token',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: 'unsubscribe/:token',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: ':lang/u/:token',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  {
    path: 'u/:token',
    component: LoadingComponent,
    resolve: {
      lang: GdprResolve
    }
  },
  // ~GDPR redirects
  {
    path: ':lang/start',
    redirectTo:
      '/:lang?utm_source=referrer&utm_medium=share-link&utm_campaign=pre-order',
    pathMatch: 'full'
  },
  {
    path: ':lang/promotion',
    redirectTo:
      '/:lang?utm_source=sms&utm_medium=sms&utm_campaign=swypetoyalloprepaid',
    pathMatch: 'full'
  },
  {
    path: ':lang/rashjunior',
    resolve: {
      src: InfluencerTrackingResolve
    },
    component: LoadingComponent
  },
  {
    path: ':lang/janosch',
    resolve: {
      src: InfluencerTrackingResolve
    },
    component: LoadingComponent
  },
  {
    path: ':lang/bendrit',
    resolve: {
      src: InfluencerTrackingResolve
    },
    component: LoadingComponent
  },
  {
    path: ':lang/noelia',
    resolve: {
      src: InfluencerTrackingResolve
    },
    component: LoadingComponent
  },
  {
    path: ':lang/ivan',
    resolve: {
      src: InfluencerTrackingResolve
    },
    component: LoadingComponent
  },

  { path: ':lang/preview', canActivate: [PreviewRedirectGuard], children: [] },
  {
    path: ':lang/404',
    component: NotFoundPageComponent
  },

  {
    path: ':lang/:uid',
    component: LandingPageComponent,
    resolve: {
      src: ReferrerTrackingResolve,
      prismicContent: PrismicResolve
    }
  },
  {
    path: '',
    component: LoadingComponent,
    resolve: {
      lang: LangaugeResolve
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

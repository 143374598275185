<div class="container-fluid auth-container">
  <div class="row">
    <div class="col content-container">
      <a routerLink="/{{ lang.current }}" class="return"
        ><img src="/assets/new/chevron_return.svg" />{{
          "WEBSITE" | translate
        }}</a
      >
      <ng-content></ng-content>
    </div>
    <div class="col phone-container">
      <img src="/assets/new/phone.png" alt="phone"/>
    </div>
  </div>
</div>

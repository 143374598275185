import { Component, Input, OnInit } from '@angular/core';
import { IntroSlice } from '../slices/intro-slice';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  @Input() sliceData: IntroSlice;
  constructor() {}

  ngOnInit(): void {}
}

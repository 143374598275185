import { Component, OnInit } from '@angular/core';
import { ForgotPassword } from '../DTO/ForgotPassword';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AmplifyService } from '../amplify/amplify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from '../email.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  value: string;
  valid: boolean;
  email: string;
  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private amplifyService: AmplifyService,
    private router: Router,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private emailService: EmailService
  ) {
    this.valid = false;
  }

  model = new ForgotPassword('');

  submitted = false;

  onChange(event: KeyboardEvent) {
    this.model = new ForgotPassword((event.target as any).value + event.key);
  }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      email: ['Default value']
    });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async onSubmit() {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    this.submitted = true;

    const result = await this.amplifyService.startRecovery(this.email);
    const lang = this.route.snapshot.params[`lang`];

    if (result) {
      this.emailService.sendEmail(this.email);
      this.loading = false;
      this.router.navigate(['/' + lang + '/check-your-email/']);
      return;
    }

    this.loading = false;
    this.notifier.notify('error', this.translate.instant('EMAIL_INCORRECT'));
  }

  returnToSignIn() {
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  reset() {
    this.resetPasswordForm.reset();
  }

  onEmailInput(event) {
    const value = event.target.value;
    this.email = value;
    this.valid = this.validateEmail(value);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  animations: [
    trigger('popUpInOut', [
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LanguageSelectComponent implements OnInit {
  @Input() type: 'header' | 'footer' | null;
  dropdownOpen = false;
  isMobileDevice = false;
  languages = ['en', 'de', 'fr', 'it'];
  constructor(public lang: LanguageService) {}

  redirectToLang(lang) {
    this.lang.goToLang(lang);
  }

  checkForMobileDevice = () => {
    if (window.screen.width < 1024) {
      this.isMobileDevice = true;
    }
  };

  ngOnInit(): void {
    this.checkForMobileDevice();
  }
}

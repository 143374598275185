<div class="slice content center-content" id="{{ sliceData.id }}">
  <div class="intro-container content-splitter reversed">
    <div
      class="split-content title-container align-content-vertically-at-start"
    >
      <h1 class="page-title" [innerHTML]="sliceData.title"></h1>
      <p class="description">
        <span>{{ sliceData.description }}</span>
      </p>
      <div class="home-points" *ngIf="sliceData.show_features">
        <div class="point" *ngFor="let feature of sliceData.features">
          <img src="/assets/new/homepoint.svg" alt="" />
          <span>{{ feature }}</span>
        </div>
      </div>
      <app-sign-up-button location="intro banner" className="in-intro">{{
        "GET_SWYPE" | translate
      }}</app-sign-up-button>
    </div>
    <div
      class="split-content image-container align-content-vertically-at-start"
    >
      <div class="background">
        <img [src]="sliceData.backgroundImage" alt="" />
      </div>
      <div class="phone">
        <img [src]="sliceData.mainImage" alt="phone image" />
      </div>
    </div>
  </div>
</div>

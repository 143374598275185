<div class="page-wrapper imprint-page">
  <app-header [currentSite]=""></app-header>
  <div class="page-content">
    <h1 class="content-title">{{ "COMPANYNAME" | translate }}</h1>
    <p class="content-description">
      Thurgauerstrasse 101B<br />
      8152 Glattpark (Opfikon)<br />
    </p>
  </div>
  <div class="page no-gradient">
    <app-footer></app-footer>
  </div>
</div>

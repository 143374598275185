<app-auth-container>
  <notifier-container></notifier-container>
  <div class="sign-up-container">
    <div class="content-title">{{ "CREATE_ACCOUNT" | translate }}</div>
    <div class="content-description">
      <span>{{ "ALREADY_HAVE_ACCOUNT" | translate }}</span>
      <span (click)="goToSignIn()"
        >{{ "SIGN_IN_BTN" | translate }} <img src="/assets/new/chevron_red.svg"
      /></span>
    </div>
    <app-social-login *ngIf="!isFacebookInAppBrowser"></app-social-login>
    <br *ngIf="isFacebookInAppBrowser">
    <div class="form-container">
      <app-text-field
        [authentication]="true"
        [disabled]="false"
        (paste)="onPaste($event, 'email')"
        (keyup)="onEmailInput($event)"
        [placeholder]="'EMAIL' | translate"
      >
      </app-text-field>
      <app-text-field
        [authentication]="true"
        type="password"
        (paste)="onPaste($event, 'password')"
        (keyup)="onPasswordInput($event)"
        [disabled]="false"
        [placeholder]="'PASSWORD' | translate"
        (keyup.enter)="signUp($event)"
      >
      </app-text-field>
      <app-password-requirements
        [requirements]="requirements"
      ></app-password-requirements>
      <a (click)="signUp($event)" class="button-container">
        <button class="sign-in-button" [ngClass]="{ active: valid }">
          {{ "CREATE_ACCOUNT" | translate }}
        </button>
      </a>
    </div>
    <div class="terms">
      <span>{{ "TERMS1" | translate }} </span
      ><a routerLink="/{{ lang }}/terms" target="_blank">{{
        "TERMS2" | translate
      }}</a
      ><span> {{ "TERMS3" | translate }} </span
      ><a routerLink="/{{ lang }}/data-protection">{{
        "TERMS4" | translate
      }}</a
      ><span> {{ "TERMS5" | translate }}</span>
    </div>
  </div>
</app-auth-container>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

import { StorageType } from "./storage-type.enum";

export class StorageEntryConfig {
  static readonly UTM_CAMPAIGN = new StorageEntryConfig(
    'utm_campaign',
    StorageType.SESSION,
    0
  );

  static readonly INFLUENCER_CAMPAIGN = new StorageEntryConfig(
    'influencer_campaign',
    StorageType.SESSION,
    0
  );

  static readonly USER_LOGGED_ONCE = new StorageEntryConfig(
    'user_logged_once',
    StorageType.LOCAL,
    0
  );

  static readonly ANONYMOUS_USER_ID = new StorageEntryConfig('anonymous_user_id', StorageType.LOCAL, 0)

  /**
   *
   * @param key string
   * @param type whether it uses local or session storage
   * @param duration for local storage, how long it should be kept; use 0 for unlimited
   */
  private constructor(
    public readonly key: string,
    public readonly type: StorageType,
    public readonly duration: number
  ) { }
}

<div class="page-wrapper error-page">
  <app-header [currentSite]=""></app-header>
  <div class="page-content">
    <div class="content">
      <div
        [style]="{ width: logoSize * 2 + 'px' }"
        class="logo"
        (click)="animateLogo()"
      >
        <ng-lottie
          [options]="lottieConfig"
          [height]="logoSize + 'px'"
          (animationCreated)="handleAnimation($event)"
          [styles]="styles"
        >
        </ng-lottie>
      </div>
      <h1 class="content-title" translate="NOT_FOUND_TITLE"></h1>
      <p class="content-subtitle" translate="NOT_FOUND_DETAILS"></p>
    </div>
  </div>
  <div class="page no-gradient">
    <app-footer></app-footer>
  </div>
</div>

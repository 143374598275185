<div class="suspended-account">
  <img src="/assets/new/cross_outline.svg" />
  <h2 class="content-title">{{ "PENDING_DELETION" | translate }}</h2>
  <p class="content-description">
    {{ "SUSPENDED_ACCOUNT_TEXT1" | translate }}
    <strong class="date">{{ suspensionDate }}</strong
    >.
    {{ "SUSPENDED_ACCOUNT_TEXT2" | translate }}
  </p>
  <a href="{{ serviceLink }}">
    <button class="sign-in-button">
      {{ "CONTACT_SUPPORT" | translate }}
    </button>
  </a>
</div>

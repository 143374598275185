import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  animate,
  state
} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* => *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100%',
        opacity: 1
      }),
      { optional: true }
    ),
    query(':enter', style({ position: 'fixed', zIndex: 2, opacity: 0 }), {
      optional: true
    }),
    query(':leave', animateChild(), { optional: true }),
    query(
      ':enter',
      animate(
        '300ms ease-in',
        style({ transform: 'translateX(0)', opacity: 1 })
      ),
      { optional: true }
    ),
    query(':leave', animate('300ms', style({ opacity: 0 })), {
      optional: true
    }), // Just there to avoid leave page flashing out
    query(':enter', animateChild(), { optional: true })
  ])
]);

export const hideFormAnimation = trigger('pageContent', [
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'translateY(100%) scale(0.75)',
      position: 'relative'
    })
  ),
  state(
    'shown',
    style({
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      position: 'relative'
    })
  ),
  transition('shown => hidden', animate('0.5s ease')),
  transition('hidden => shown', animate('0.5s ease'))
]);

export const hideFormAnimationTwint = trigger('pageContent', [
  state(
    'hidden',
    style({
      opacity: 0,
      transform: 'translateY(-100%) scale(0.75)',
      position: 'relative',
      maxHeight: '0'
    })
  ),
  state(
    'shown',
    style({
      opacity: 1,
      transform: 'translateY(0) scale(1)',
      position: 'relative',
      maxHeight: '490px'
    })
  ),
  transition('shown => hidden', animate('0.5s ease')),
  transition('hidden => shown', animate('0.5s ease'))
]);

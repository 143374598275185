import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { TrackingService } from '../tracking.service';
import { WebAppService } from '../api-client/api/webApp.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent {
  lang: string;
  loading = false;

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute,
    private tracking: TrackingService,
    private webAppService: WebAppService,
    private notifier: NotifierService,
    private translate: TranslateService
  ) {
    this.lang = this.route.snapshot.params[`lang`];
  }

  getLanguage() {
    switch (this.route.snapshot.params[`lang`]) {
      case 'en':
        return 'en_US';
      case 'de':
        return 'de_CH';
      case 'it':
        return 'it_CH';
      case 'fr':
        return 'fr_CH';
      default:
        return 'en_US';
    }
  }

  async getCurrentUser() {
    this.loading = true;
    const user = await this.amplifyService.getCurrentUser();
    if (user) {
      (
        await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
      ).subscribe(
        () => {
          this.loading = false;
          this.router.navigate(['/' + this.lang + '/dashboard']);
        },
        async error => {
          console.log(error);
          if (error.status === 404) {
            await this.createUser(user);
            this.loading = false;
            this.router.navigate(['/' + this.lang + '/dashboard']);
          }
        }
      );
    }
  }

  async createUser(user) {
    return new Promise(async (resolve, reject) => {
      (
        await this.webAppService.webUsersPut({
          id: user.attributes.sub,
          languageKillbill: this.getLanguage(),
          email: user.attributes.email,
          domain: user.loginMethod
        })
      ).subscribe(
        data => {
          resolve(data);
        },
        error => {
          console.log(error);
          this.notifier.notify(
            'error',
            this.translate.instant('OPERATION_FAILED')
          );
          reject(error);
        }
      );
    });
  }

  async loginUsingFacebook(event) {
    this.tracking.signUpInitiated('facebook');
    const result = await this.amplifyService.signInFacebook();
    console.log('loginUsingFacebook result: ' + result);
    if (result) {
      await this.getCurrentUser();
    }
  }

  async loginUsingGoogle(event) {
    this.tracking.signUpInitiated('google');
    const result = await this.amplifyService.signInGoogle();
    console.log('signInGoogle result', result);
    if (result) {
      await this.getCurrentUser();
    }
  }
}

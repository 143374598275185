<div
  class="slice"
  [ngClass]="[
    sliceData.margin_bottom ? sliceData.margin_bottom : 'small-margin-bottom',
    sliceData.margin_top ? sliceData.margin_top : 'small-margin-top'
  ]"
  [attr.id]="sliceData.id"
>
  <div class="content new-features-container">
    <ng-container *ngFor="let item of sliceData.items; let even = even">
      <div class="new-feature-item" [ngClass]="{ reversed: even }">
        <div class="image-container">
          <img
            *ngIf="item.img"
            class="background-image"
            [src]="item.img"
            alt="background image"
          />
          <img *ngIf="item.icon" class="icon" [src]="item.icon" alt="icon" />
        </div>
        <div class="text-container">
          <h3 class="content-title">{{ item.title }}</h3>
          <p class="content-description">{{ item.description }}</p>
          <ng-container *ngIf="item.button_text">
            <a
              *ngIf="!item.external_redirect"
              routerLink="/{{ lang.current }}/{{ item.button_link }}"
            >
              <button class="sign-in-button">{{ item.button_text }}</button></a
            >
            <a *ngIf="item.external_redirect" href="{{ item.button_link }}"
              ><button class="sign-in-button">{{ item.button_text }}</button></a
            >
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

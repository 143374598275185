<section
  id="faqs"
  class="slice"
  [ngClass]="[
    sliceData.margin_bottom ? sliceData.margin_bottom : 'small-margin-bottom',
    sliceData.margin_top ? sliceData.margin_top : 'small-margin-top'
  ]"
>
  <div class="content center-content">
    <h2 class="tariffs-title content-title">{{ sliceData.section_title }}</h2>
    <div class="faqs-container">
      <ng-container *ngFor="let item of sliceData.items; let i = index">
        <div
          class="faq-item"
          [ngClass]="{ open: faqOpen === i }"
          [ngStyle]="{
            height: faqOpen === i ? getFaqHeight(i) + 'px' : '58px'
          }"
        >
          <div class="faq-header" (click)="toggleFaq(i)">
            <span>{{ item.faq_header }}</span>
            <img *ngIf="faqOpen === i" src="/assets/new/faq_open.svg" />
            <img *ngIf="faqOpen !== i" src="/assets/new/faq_close.svg" />
          </div>
          <div class="faq-body" [attr.data-id]="i">
            <p>{{ item.faq_body }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<div class="password-requirements-container">
  <ul>
    <li [ngClass]="{ inactive: !requirements.minimumEightCharacters }">
      <img
        *ngIf="!requirements.minimumEightCharacters"
        src="/assets/new/grey_cross.svg"
      />
      <img
        *ngIf="requirements.minimumEightCharacters"
        src="/assets/new/green_check.svg"
      />
      {{ "PASSWORD_REQUIREMENT1" | translate }}
    </li>
    <li [ngClass]="{ inactive: !requirements.oneLowerCaseLetter }">
      <img
        *ngIf="!requirements.oneLowerCaseLetter"
        src="/assets/new/grey_cross.svg"
      />
      <img
        *ngIf="requirements.oneLowerCaseLetter"
        src="/assets/new/green_check.svg"
      />
      {{ "PASSWORD_REQUIREMENT2" | translate }}
    </li>
    <li [ngClass]="{ inactive: !requirements.oneUpperCaseLetter }">
      <img
        *ngIf="!requirements.oneUpperCaseLetter"
        src="/assets/new/grey_cross.svg"
      />
      <img
        *ngIf="requirements.oneUpperCaseLetter"
        src="/assets/new/green_check.svg"
      />
      {{ "PASSWORD_REQUIREMENT3" | translate }}
    </li>
    <li [ngClass]="{ inactive: !requirements.atLeastOneDigit }">
      <img
        *ngIf="!requirements.atLeastOneDigit"
        src="/assets/new/grey_cross.svg"
      />
      <img
        *ngIf="requirements.atLeastOneDigit"
        src="/assets/new/green_check.svg"
      />
      {{ "PASSWORD_REQUIREMENT4" | translate }}
    </li>
    <li [ngClass]="{ inactive: !requirements.oneSpecialSymbol }">
      <img
        *ngIf="!requirements.oneSpecialSymbol"
        src="/assets/new/grey_cross.svg"
      />
      <img
        *ngIf="requirements.oneSpecialSymbol"
        src="/assets/new/green_check.svg"
      />
      {{ "PASSWORD_REQUIREMENT5" | translate }}
    </li>
  </ul>
</div>

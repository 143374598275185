import { Component, Input, OnInit } from '@angular/core';
import { FourItemsSlice } from '../slices/four-items-slice';

@Component({
  selector: 'app-four-items',
  templateUrl: './four-items.component.html',
  styleUrls: ['./four-items.component.scss']
})
export class FourItemsComponent implements OnInit {
  @Input() sliceData: FourItemsSlice;
  constructor() {}

  ngOnInit(): void {}
}

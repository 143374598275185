import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AnchorScrollService {
  timeoutsPerFragment = [];

  constructor(private router: ActivatedRoute) {}

  listen() {
    this.router.fragment.subscribe(fragment => {
      if (!fragment) {
        this.timeoutsPerFragment = [];
      } else if (fragment && !this.timeoutsPerFragment.includes(fragment)) {
        this.timeoutsPerFragment.push(fragment);
        setTimeout(() => {
          this.timeoutsPerFragment.filter(el => el !== fragment);
          try {
            document.getElementById(fragment).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          } catch (e) {}
        }, 250);
      }
    });
  }
}

<div (clickOutside)="dropdownOpen = false">
  <ng-container *ngIf="type === 'header'">
    <button (click)="dropdownOpen = !dropdownOpen" class="header-item">
      <span *ngIf="!isMobileDevice">
        <img class="globe" src="/assets/new/globe_white.svg" />{{
          this.lang.current | titlecase
        }}</span
      >

      <span *ngIf="isMobileDevice">
        <img class="globe" src="/assets/new/globe_dark.svg" />{{
          this.lang.current | uppercase | translate
        }}</span
      >
    </button></ng-container
  >
  <button
    *ngIf="type === 'footer'"
    (click)="dropdownOpen = !dropdownOpen"
    class="footer-item"
  >
    {{ this.lang.current | uppercase | translate }}
    <img src="/assets/new/white_chevron_down.svg" />
  </button>
  <app-dropdown
    @popUpInOut
    *ngIf="dropdownOpen"
    type="language"
    [direction]="type === 'header' ? 'to_bottom' : 'to_top'"
  >
    <ng-container *ngFor="let language of languages">
      <a
        class="dropdown-item"
        [ngClass]="{ active: this.lang.current === language }"
        (click)="redirectToLang(language)"
        ><p class="title">{{ language | uppercase | translate }}</p></a
      >
    </ng-container>
  </app-dropdown>
</div>

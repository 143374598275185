<app-auth-container>
  <notifier-container></notifier-container>
  <div class="renew-password-container">
    <div class="content-title">{{ "CREATE_PASSWORD" | translate }}</div>
    <div class="content-description">
      <span>{{ "CREATE_PASSWORD_DESCRIPTION" | translate }}</span>
    </div>
    <div class="form-container">
      <form [formGroup]="passwordForm">
        <app-text-field
          formControlName="password"
          (keyup)="onInput($event)"
          [value]="value"
          (change)="value = $event.target.value"
          [disabled]="false"
          [placeholder]="'PASSWORD' | translate"
          type="password"
        >
        </app-text-field>
      </form>
      <app-password-requirements
        [requirements]="requirements"
      ></app-password-requirements>
      <a (click)="submit($event)" class="button-container">
        <button class="sign-in-button" [ngClass]="{ active: valid }">
          {{ "SET_PASSWORD" | translate }}
        </button>
      </a>
      <div class="remember-password">
        <span>{{ "REMEMBER_PASSWORD" | translate }}</span>
        <button (click)="returnToSignIn()">
          {{ "LOGIN" | translate }}
          <img
            src="/assets/new/chevron_red.svg"
            class="arrow"
            alt="forgot password arrow"
          />
        </button>
      </div>
    </div>
  </div>
</app-auth-container>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

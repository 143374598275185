import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import closestTo from 'date-fns/closestTo';
import intervalToDuration from 'date-fns/intervalToDuration';
import isAfter from 'date-fns/isAfter';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() endDates: [Date];
  remaining: Duration;
  interval: any;

  constructor() {}

  ngOnInit() {
    this.remaining = this.getRemainingTime();

    this.interval = interval(1000).subscribe(() => {
      this.remaining = this.getRemainingTime();
    });
  }

  public ngOnDestroy() {
    this.interval.unsubscribe();
  }

  getRemainingTime() {
    const now = new Date();
    const to = closestTo(
      now,
      this.endDates?.filter(d => isAfter(d, now))
    );
    if (!to) return undefined;
    return intervalToDuration({
      start: now,
      end: to
    });
  }
}

<app-auth-container>
  <notifier-container></notifier-container>
  <div class="new-email-verification">
    <div class="content-title">{{ "VERIFY_EMAIL" | translate }}</div>
    <div class="content-description">
      <p>{{ "CHECK_EMAIL" | translate }}</p>
      <p class="email">{{ email }}</p>
    </div>
    <div class="form-container">
      <form class="letter-input-container">
        <ng-container
          *ngFor="
            let _ of [].constructor(6);
            let first = first;
            let last = last
          "
        >
          <input
            autocomplete="off"
            class="letter-input"
            (paste)="first ? onPasteCode($event) : null"
            (keyup)="
              last ? onDigitInput($event, true) : onDigitInput($event, false)
            "
            maxlength="1"
            type="text"
          />
        </ng-container>
      </form>
      <div class="resend-email">
        <span> {{ "NOT_RECEIVED" | translate }}</span>
        <button (click)="resendConfirmationCode()">
          {{ "RESEND" | translate }}
        </button>
      </div>
    </div>
  </div>
</app-auth-container>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

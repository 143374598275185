import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit
} from '@angular/core';
import { FormControlName, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFieldComponent implements OnInit {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @Input()
  set value(value: any) {
    this._value = value;
    this.onChange(value);
  }
  get value(): any {
    return this._value;
  }

  @Input()
  disabled = false;

  @Input()
  error: boolean;

  @Input()
  authentication: boolean;

  @Input()
  placeholder = '';

  @Input()
  required = false;

  @Input()
  labelText = '';

  @Input()
  labelClass: string;

  @Input()
  type = 'text';

  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  formControlName: FormControlName;

  @Input()
  list: string;

  isPasswordType = false;
  focused = false;
  private _value: any;

  onChange = event => {};
  onTouched = () => {};

  onFocus() {
    this.focused = true;
  }

  onFocusOut() {
    this.focused = false;
  }

  ngOnInit(): void {
    this.isPasswordType = this.type === 'password';
  }

  onToggleShowPassword() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  writeValue(value: any) {
    this.value = value;
    if (this.changeDetectorRef) {
      this.changeDetectorRef.markForCheck();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}

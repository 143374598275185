import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { FeatureSlice } from '../slices/feature-slice';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  @Input() sliceData: FeatureSlice;
  constructor(public lang: LanguageService) {}

  ngOnInit(): void {}
}

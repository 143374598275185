import { Component, Input, OnInit } from '@angular/core';
import { ImageWithContentSlice } from '../slices/image-with-content-slice';

@Component({
  selector: 'app-image-with-content',
  templateUrl: './image-with-content.component.html',
  styleUrls: ['./image-with-content.component.scss']
})
export class ImageWithContentComponent implements OnInit {
  @Input() sliceData: ImageWithContentSlice;
  constructor() {}

  ngOnInit(): void {}
}

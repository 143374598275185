import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-or-divider',
  templateUrl: './or-divider.component.html',
  styleUrls: ['./or-divider.component.scss']
})
export class OrDividerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, Input, OnInit } from '@angular/core';
import { TutorialStepsSlice } from '../slices/tutorial-steps-slice';
import { OpenAppStoreService } from '../open-app-store.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {
  @Input() sliceData: TutorialStepsSlice;
  isMobileDevice = false;
  isAndroid: boolean;

  constructor(public storeService: OpenAppStoreService) {}

  ngOnInit(): void {
    this.isMobileDevice = this.checkForMobileDevice();
    this.isAndroid = this.checkIsAndroid();
  }

  openStore() {
    this.checkIsAndroid()
      ? this.storeService.openAppStore('google')
      : this.storeService.openAppStore('apple');
  }

  checkIsAndroid(): boolean {
    const userAgent = navigator.userAgent;
    return /android/i.test(userAgent) ? true : false;
  }

  checkForMobileDevice = () => {
    return window.screen.width < 768 ? true : false;
  };
}

import { InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

// export const BASE_PATH = new InjectionToken<string>('basePath');
export const BASE_PATH = environment.production
  ? 'https://api.prod.swype.ch'
  : (environment.staging ? 'https://api.stage.swype.ch'
    : 'https://api.dev.swype.ch')

export const COLLECTION_FORMATS = {
  csv: ',',
  tsv: '   ',
  ssv: ' ',
  pipes: '|'
};

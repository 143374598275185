import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownComponent implements OnInit {
  @Input() type: 'tarrifs' | 'more' | 'language' | null;
  @Input() direction: 'to_top' | 'to_bottom' | null;
  constructor() {}

  ngOnInit(): void {}
}

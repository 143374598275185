import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client/api/webApp.service';
import { LanguageService } from '../language.service';
import { StateService } from '../state.service';
import { TrackingService } from '../tracking.service';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('popUpInOut', [
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() currentSite: string;
  isMobileDevice = false;
  dropdownOpen: 'tarrifs' | 'more' | null;
  loggedIn: boolean;
  user: string;
  backgroundClass: string;
  routeSub: Subscription;
  supportLink: string;

  constructor(
    public lang: LanguageService,
    public state: StateService,
    private amplifyService: AmplifyService,
    private webAppService: WebAppService,
    private tracking: TrackingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  checkForMobileDevice = () => {
    if (window.screen.width < 1024) {
      this.isMobileDevice = true;
    }
  };

  toggleMenu() {
    this.state.menuActive = !this.state.menuActive;
  }

  showDropdown(modalType: 'tarrifs' | 'more' | null) {
    this.dropdownOpen === modalType
      ? (this.dropdownOpen = null)
      : (this.dropdownOpen = modalType);
  }

  hideDropdown(modalType) {
    if (this.dropdownOpen === modalType) {
      this.dropdownOpen = null;
    }
  }

  async signOut() {
    await this.amplifyService.signOut();
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  getLanguage() {
    switch (this.route.snapshot.params[`lang`]) {
      case 'en':
        return 'en_US';
      case 'de':
        return 'de_CH';
      case 'it':
        return 'it_CH';
      case 'fr':
        return 'fr_CH';
      default:
        return 'en_US';
    }
  }

  async getCurrentUser() {
    const user = await this.amplifyService.getCurrentUser();
    if (user) {
      this.user = user.attributes.email;
      this.loggedIn = this.user && !!this.user.length;

      (
        await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
      ).subscribe(
        (data: any) => {
          this.user = data.data.name.split(' ')[0];
        },
        async error => {
          console.log(error);
          if (error.status === 404) {
            await this.createUser(user);
            window.location.reload();
          }
        }
      );
    }
  }

  async createUser(user) {
    return new Promise(async (resolve, reject) => {
      (
        await this.webAppService.webUsersPut({
          id: user.attributes.sub,
          languageKillbill: this.getLanguage(),
          email: user.attributes.email,
          domain: user.loginMethod
        })
      ).subscribe(
        data => {
          this.tracking.ctaClick('website user registered');
          resolve(data);
        },
        error => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  async ngOnInit() {
    window.location.pathname === '/en'
      ? (this.supportLink = `https://support.swype.ch/hc/en-gb`)
      : (this.supportLink = `https://support.swype.ch/hc/${this.lang.current}`);
    this.routeSub = this.route.params.subscribe(params => {
      params[`uid`]?.includes('swype') ||
      params[`uid`] === 'international' ||
      params[`uid`] === 'roaming'
        ? (this.backgroundClass = 'transparent')
        : (this.backgroundClass = '');
    });
    await this.getCurrentUser();
    this.dropdownOpen = null;
    this.checkForMobileDevice();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}

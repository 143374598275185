import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-suspended-account',
  templateUrl: './suspended-account.component.html',
  styleUrls: ['./suspended-account.component.scss']
})
export class SuspendedAccountComponent implements OnInit {
  @Input()
  suspensionDate;

  @Input()
  lang;

  serviceLink: string;
  languages = { de: 'de-ch', en: 'en-gb', fr: 'fr-ch', it: 'it-ch' };

  constructor() {}

  ngOnInit(): void {
    const local = this.languages[this.lang];
    this.serviceLink = `https://support.swype.ch/hc/${this.lang}/requests/new?ticket_form_id=360000065837`;
    this.suspensionDate = new Date(this.suspensionDate);
    this.suspensionDate = this.suspensionDate.setDate(
      this.suspensionDate.getDate() + 30
    );
    this.suspensionDate = new Date(this.suspensionDate);
    this.suspensionDate = this.suspensionDate.toLocaleDateString(`${local}`, {
      day: 'numeric',
      year: 'numeric',
      month: 'long'
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  isMobileDevice = false;

  constructor(public lang: LanguageService) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.isMobileDevice = this.checkForMobileDevice();
  }

  checkForMobileDevice = () => {
    return window.screen.width < 768 ? false : true;
  };
}

<div class="slice" id="getSwype">
  <div class="content content-splitter" id="early-access">
    <div class="split-content center-content-vertically-on-desktop">
      <app-animated-sim
        *ngIf="sliceData.animateImage && sliceData.img"
        [imgSrc]="sliceData.img"
        class="sim-card"
      >
      </app-animated-sim>
      <img
        *ngIf="!sliceData.animateImage && sliceData.img"
        [src]="sliceData.img"
      />
    </div>
    <div
      class="split-content center-content-vertically-on-desktop space-above-on-mobile"
    >
      <div class="page-intro center-text-on-mobile">
        <p class="page-slogan">
          <span>{{ "MOBILE_JUST_BETTER" | translate }}</span>
        </p>
        <span class="page-sub-title" [innerHtml]="sliceData.title"></span>
        <p class="page-claim">
          <span [innerHTML]="sliceData.claim"></span>
        </p>
        <p class="page-claim-secondary bigger">
          <img
            class="page-checkmark"
            src="/assets/icons/checkmark-icon-intro.svg"
            alt="checkmark"
          />
          {{ "AVAILABLE_FOR_IOS_AND_ANDROID" | translate }}
        </p>
        <div class="app-store-links">
          <img
            (click)="storeService.openAppStore('apple')"
            src="/assets/icons/app-store-badge-{{ lang.current }}.svg"
            alt="apple store"
            class="app-store"
          />
          <img
            (click)="storeService.openAppStore('google')"
            src="/assets/icons/google-play-badge-{{ lang.current }}.png"
            alt="google play store"
            class="google-play"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tariff-item" *ngIf="tariff" [class.short]="classShort">
  <p class="tariff-name">{{ tariff.tariff_name }}</p>
  <div class="tariff-price">
    <span class="currency">CHF</span>{{ tariff.tariff_price }}
  </div>
  <div class="tariff-plans">
    <div class="tariff-plan" *ngIf="tariff.internet_package">
      <div class="plan-icon">
        <img [src]="tariff.internet_icon" alt=""/>
      </div>
      <div *ngIf="tariffToDisplay !== 'custom'">
        <p class="plan-name">{{ tariff.internet_package | translate }}</p>
        <p class="plan-details">{{ tariff.internet_details | translate }}</p>
      </div>
      <div *ngIf="tariffToDisplay === 'custom'">
        <p class="plan-name">{{ tariff.internet_package }}</p>
        <p class="plan-details">{{ tariff.internet_details }}</p>
      </div>
    </div>
    <div class="tariff-plan" *ngIf="tariff.calling_package">
      <div class="plan-icon">
        <img [src]="tariff.calling_icon" alt="" />
      </div>
      <div *ngIf="tariffToDisplay !== 'custom'">
        <p class="plan-name">{{ tariff.calling_package | translate }}</p>
        <p class="plan-details">{{ tariff.calling_details | translate }}</p>
      </div>
      <div *ngIf="tariffToDisplay === 'custom'">
        <p class="plan-name">{{ tariff.calling_package }}</p>
        <p class="plan-details">{{ tariff.calling_details }}</p>
      </div>
    </div>
    <div class="tariff-plan" *ngIf="tariff.roaming_package">
      <div class="plan-icon">
        <img [src]="tariff.roaming_icon" alt="" />
      </div>
      <div *ngIf="tariffToDisplay !== 'custom'">
        <p class="plan-name">{{ tariff.roaming_package | translate }}</p>
        <p class="plan-details">{{ tariff.roaming_details | translate }}</p>
      </div>
      <div *ngIf="tariffToDisplay === 'custom'">
        <p class="plan-name">{{ tariff.roaming_package }}</p>
        <p class="plan-details">{{ tariff.roaming_details }}</p>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <app-sign-up-button
      *ngIf="tariff.button_text && tariffToDisplay !== 'custom'"
      className="tariff-button"
      location="tariff banner"
    >
      {{ tariff.button_text | translate }}</app-sign-up-button
    >
    <button
      *ngIf="!classShort && tariffToDisplay !== 'custom'"
      class="more"
      (click)="redirectToTariffPage()"
    >
      <img src="/assets/new/info.svg" alt="more information" />{{
        "MORE_INFO" | translate
      }}
    </button>
    <button
      *ngIf="!classShort && tariffToDisplay === 'custom'"
      class="sign-in-button tariff-button"
      (click)="redirectToTariffPage()"
    >
      {{ "MORE_INFO" | translate }}
    </button>
  </div>
</div>

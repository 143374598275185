import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AmplifyService } from '../amplify/amplify.service';
import { ForgotPassword } from '../DTO/ForgotPassword';
import { TranslateService } from '@ngx-translate/core';
import { WebAppService } from '../api-client';
import { TrackingService } from '../tracking.service';
import { EmailService } from '../email.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  myForm: UntypedFormGroup;
  value: string;
  valid: boolean;
  code: string;
  email: string;
  codeInputBlocked: boolean;
  emailPresent: boolean;
  loading = false;
  emailSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private amplify: AmplifyService,
    private translate: TranslateService,
    private webAppService: WebAppService,
    private tracking: TrackingService,
    private emailService: EmailService
  ) {}

  model = new ForgotPassword('');

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  async resendConfirmationCode() {
    this.loading = true;
    await this.amplify.resendConfirmationCode(this.email);
    this.loading = false;
    this.notifier.notify(
      'success',
      this.translate.instant('CONFIRMATION_CODE_RESENT')
    );
  }

  ngOnInit(): void {
    this.emailPresent = false;
    this.code = this.route.snapshot.params[`code`];

    if (!this.code) {
      this.code = '';
    }

    this.emailSubscription = this.emailService.email$.subscribe(email => {
      this.email = email;
      this.emailPresent = true;
    });
  }

  goToForgotPassword() {
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/forgot-password']);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidity() {
    this.valid = this.validateEmail(this.email);
  }

  onPaste(event: ClipboardEvent, field) {
    this[field] = event.clipboardData.getData('text');
    this.checkValidity();
  }

  onEmailInput(event) {
    const value = event.target.value;
    this.email = value;
    this.checkValidity();
  }

  onPasteCode(event) {
    const code = event.clipboardData.getData('text');
    let element = event.srcElement;

    for (let i = 0; i < code.length; i++) {
      element.value = code[i];
      element = element.nextElementSibling;
    }
    if (this.email) {
      this.valid = true;
    }
    setTimeout(() => {
      this.code = code;
      this.activateAccount();
    }, 500);
    return false;
  }

  async activateAccount() {
    if (!this.email) {
      return;
    }
    this.loading = true;
    const lang = this.route.snapshot.params[`lang`];
    const result = await this.submit(this.code);
    if (result === 'NotAuthorizedException') {
      this.loading = false;
      this.showNotification('error', this.translate.instant('USER_CONFIRMED'));
      setTimeout(() => {
        this.router.navigate(['/' + lang + '/sign-in']);
      }, 2000);
      return;
    }
    if (
      result === 'CodeMismatchException' ||
      result === 'InvalidParameterException'
    ) {
      this.loading = false;
      this.showNotification(
        'error',
        this.translate.instant('INVALID_VERIFICATION_CODE')
      );
      return;
    }
    if (
      result &&
      result !== 'UserNotFoundException' &&
      result !== 'CodeMismatchException' &&
      result !== 'InvalidParameterException'
    ) {
      this.loading = false;
      this.showNotification(
        'success',
        this.translate.instant('PROVIDED_CODE_CORRECT')
      );
      this.valid = false;

      if (!this.amplify.password) {
        setTimeout(() => {
          this.router.navigate(['/' + lang + '/sign-in']);
        }, 2000);
        return;
      }

      try {
        await this.amplify.signIn(this.email, this.amplify.password);
        const user = await this.amplify.getCurrentUser();
        if (user) {
          (
            await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
          ).subscribe(
            (data: any) => {
              setTimeout(() => {
                this.router.navigate(['/' + lang + '/download-app']);
                this.tracking.signUpVerified();
              }, 2000);
            },
            async error => {
              if (error.status === 404) {
                this.loading = true;
                await this.createUser(user);
                setTimeout(() => {
                  this.loading = false;
                  this.router.navigate(['/' + lang + '/download-app']);
                  this.tracking.signUpVerified(user.attributes.email);
                }, 2000);
              }
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.showNotification(
        'error',
        this.translate.instant('PROVIDED_CODE_EMAIL_INCORRECT')
      );
      this.loading = false;
    }
  }

  getLanguage() {
    switch (this.route.snapshot.params[`lang`]) {
      case 'en':
        return 'en_US';
      case 'de':
        return 'de_CH';
      case 'it':
        return 'it_CH';
      case 'fr':
        return 'fr_CH';
      default:
        return 'en_US';
    }
  }

  async createUser(user) {
    return new Promise(async (resolve, reject) => {
      (
        await this.webAppService.webUsersPut({
          id: user.username,
          languageKillbill: this.getLanguage(),
          email: user.attributes.email,
          domain: 'email'
        })
      ).subscribe(
        data => {
          this.tracking.ctaClick('website user registered');
          resolve(data);
        },
        error => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  isNumber(value) {
    return /^-?\d+$/.test(value) && value.length === 1;
  }

  async onDigitInput(event, isLastElement) {
    let element = event.srcElement;
    if (this.isNumber(event.key)) {
      element.value = event.key;
    }

    if (event.code === 'Backspace') {
      this.valid = false;
      element = event.srcElement.previousElementSibling;
      element.focus();
    }

    if (isLastElement) {
      this.code = '';
      while (element.previousElementSibling !== null) {
        element = element.previousElementSibling;
      }

      for (let i = 0; i < 6; i++) {
        this.code = this.code + element.value;
        element = element.nextElementSibling;
      }

      if (this.code.length === 6) {
        this.activateAccount();
      }
    } else {
      if (event.code !== 'Backspace')
        element = event.srcElement.nextElementSibling;

      element.focus();
    }
  }

  async submit(code) {
    const result = await this.amplify.confirmSignUp(this.email, code);
    return result;
  }

  ngOnDestroy(): void {
    this.emailSubscription.unsubscribe();
  }
}

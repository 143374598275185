<app-auth-container>
  <div class="download-app">
    <div class="content-title">{{ "GET_THE_APP" | translate }}</div>
    <div class="content-description">
      <span>{{ "GET_THE_APP_DESCRIPTION" | translate }}</span>
    </div>
    <div class="qr-code">
      <img src="/assets/new/qr_code.png" alt="qr code" width="130" height="130"/>
    </div>
    <app-store-buttons></app-store-buttons>
    <ul class="content-steps">
      <div>{{ "INFO_REGISTRATION_INAPP" | translate }}</div>
      <li>{{ "INFO_REGISTRATION_BULLET1" | translate }}</li>
      <li>{{ "INFO_REGISTRATION_BULLET2" | translate }}</li>
      <li>{{ "INFO_REGISTRATION_BULLET3" | translate }}</li>
      <li>{{ "INFO_REGISTRATION_BULLET4" | translate }}</li>
    </ul>
  </div>
</app-auth-container>

import { Component, Input, OnInit } from '@angular/core';
import { TermsSlice } from '../terms-slice';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  @Input() sliceData: TermsSlice;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { GetTheAppSlice } from '../slices/get-the-app-slice';
import { LanguageService } from '../language.service';
import { OpenAppStoreService } from '../open-app-store.service';

@Component({
  selector: 'app-get-the-app',
  templateUrl: './get-the-app.component.html',
  styleUrls: ['./get-the-app.component.scss']
})
export class GetTheAppComponent implements OnInit {
  @Input() sliceData: GetTheAppSlice;
  isMobileDevice = false;
  isAndroid: boolean;

  constructor(
    public lang: LanguageService,
    private storeService: OpenAppStoreService
  ) {}

  ngOnInit(): void {
    this.isMobileDevice = this.checkForMobileDevice();
    this.isAndroid = this.checkIsAndroid();
  }

  openStore() {
    this.checkIsAndroid()
      ? this.storeService.openAppStore('google')
      : this.storeService.openAppStore('apple');
  }

  checkIsAndroid(): boolean {
    const userAgent = navigator.userAgent;
    return /android/i.test(userAgent) ? true : false;
  }

  checkForMobileDevice = () => {
    return window.screen.width < 768 ? true : false;
  };
}

<div class="subscription-item">
  <ng-container *ngIf="!subscription.blockedSim; else simBlockedDetails">
    <div>
      <p
        *ngIf="
          subscription.tariffName && subscription.subscription === 'active'
        "
        class="subscription-name"
      >
        {{ subscription.tariffName }}
      </p>
      <p class="subscription-number">{{ subscription.number }}</p>
      <ul class="subscription-details">
        <li>
          <img src="/assets/new/green_check_outline.svg" />
          <span>{{ "SIM_ACTIVE" | translate }}</span>
        </li>
        <li>
          <img
            *ngIf="subscription.subscription == 'active'"
            src="/assets/new/green_check_outline.svg"
          />
          <img
            *ngIf="subscription.subscription == 'inactive'"
            src="/assets/new/grey_cross_outline.svg"
          />
          <span>{{ "SUBSCRIPTION" | translate | titlecase }}</span>
        </li>
        <!--<li>
          <img
            *ngIf="subscription.fiveG == 'active'"
            src="/assets/new/green_check_outline.svg"
          />
          <img
            *ngIf="subscription.fiveG == 'inactive'"
            src="/assets/new/grey_cross_outline.svg"
          />
          <span>5G</span>
        </li>-->
      </ul>
    </div>
    <div class="button-container">
      <button class="transparent-button" (click)="  setSimToBlockUnblockOperation(subscription, 'BLOCK')">
        {{ "BLOCK" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #simBlockedDetails>
    <div>
      <p
        *ngIf="
          subscription.tariffName && subscription.subscription === 'active'
        "
        class="subscription-name"
      >
        {{ subscription.tariffName }}
      </p>
      <p class="subscription-number">{{ subscription.number }}</p>
      <ul class="subscription-details">
        <li *ngIf="subscription.blockedSim !== 'notActivated'">
          <img src="/assets/new/red_cross_outline.svg" />
          <span>{{ "SIM_BLOCKED" | translate }}</span>
        </li>
        <li *ngIf="subscription.blockedSim === 'notActivated'">
          <img src="/assets/new/grey_cross_outline.svg" />
          <span>{{ "INACTIVE" | translate }}</span>
        </li>
        <li>
          <img
            *ngIf="subscription.subscription == 'active'"
            src="/assets/new/green_check_outline.svg"
          />
          <img
            *ngIf="subscription.subscription == 'inactive'"
            src="/assets/new/grey_cross_outline.svg"
          />
          <span>{{ "SUBSCRIPTION" | translate | titlecase }}</span>
        </li>
        <!--<li>
          <img
            *ngIf="subscription.fiveG == 'active'"
            src="/assets/new/green_check_outline.svg"
          />
          <img
            *ngIf="subscription.fiveG == 'inactive'"
            src="/assets/new/grey_cross_outline.svg"
          />
          <span>5G</span>
        </li>-->
      </ul>
    </div>
    <div class="button-container">
      <button
        *ngIf="subscription.blockedSim !== 'notActivated'"
        class="transparent-button"
        (click)="setSimToBlockUnblockOperation(subscription, 'UNBLOCK')"
      >
        {{ "UNBLOCK" | translate }}
      </button>
    </div>
  </ng-template>
</div>

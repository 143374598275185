import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FaqsSlice } from '../slices/faqs-slice';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  @Input() sliceData: FaqsSlice;
  faqOpen: number | null;
  constructor(private elRef: ElementRef) {}

  toggleFaq(index: number | null): void {
    this.faqOpen === index ? (this.faqOpen = null) : (this.faqOpen = index);
  }

  getFaqHeight(index: number): number {
    return (
      this.elRef.nativeElement.querySelector(`[data-id="${index}" ]`)
        .offsetHeight + 74
    );
  }

  ngOnInit(): void {
    this.faqOpen = null;
  }
}

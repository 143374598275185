import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Delay } from '../libs/delay';
import { ToastsService } from '../toasts.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-send-download-link-form',
  templateUrl: './send-download-link-form.component.html',
  styleUrls: ['./send-download-link-form.component.scss']
})
export class SendDownloadLinkFormComponent {
  @Input() ctaText: string;
  @Input() buttonExtraClass: string;
  msisdn = '';
  submitting = false;

  @Output() onFormUpdate = new EventEmitter(true);

  constructor(
    private api: ApiService,
    private toasts: ToastsService,
    public tracking: TrackingService
  ) {}

  submit(form: NgForm) {
    if (!form.valid) return this.tracking.submitForm(this.formName, form);
    this.onFormUpdate.next('started');
    this.submitting = true;
    Promise.all([this.api.sendDownloadLink(this.msisdn), Delay(500)])
      .then(results => {
        let resp = results[0];
        if (!resp) {
          this.toasts.add('DOWNLOAD_ERROR', false);
          this.onFormUpdate.next('error');
          this.tracking.submitForm(this.formName, form, 'backend error');
          return (this.submitting = false);
        }
        this.onFormUpdate.next('success');
        this.toasts.add('SMS_SENT', true);
        this.msisdn = '';
        // this.router.navigate([this.lang.current, 'update-address'])
        this.tracking.submitForm(this.formName, form);
      })
      .catch(err => {
        this.submitting = false;
        this.toasts.add('DOWNLOAD_SERVER_ERROR', false);
        this.onFormUpdate.next('error');
        console.error(err);
        this.tracking.submitForm(this.formName, form, err);
      });
  }

  blurMsisdn(form: NgForm) {
    if (form.form.controls.msisdn.valid) {
      this.tracking.formFieldBlur(this.formName, 'msisdn');
    }
  }

  get formName() {
    return 'send download link';
  }
}

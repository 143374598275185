import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent implements OnInit {
  showWarningPopup = false;
  typeOfWarning: 'block' | 'activate';
  subscriptionToChange: any;
  blockSimModal = false;
  unblockSimModal = false;
  blockConfirmation = false;
  unblockConfirmation = false;

  @Output()
  simNumberToBlockUnblock = new EventEmitter<any>();

  @Input()
  userSubscriptions;

  constructor(
    private tracking: TrackingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  hideSimModal(event): void {
    this.blockSimModal
      ? (this.blockSimModal = event)
      : (this.unblockSimModal = event);
    this.subscriptionToChange = null;
  }

  goToOrder() {
    this.tracking.ctaClick('website order started');
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/order']);
  }

  blockUnblockSim(operation: string) {
    this.simNumberToBlockUnblock.emit({
      subscription: this.subscriptionToChange,
      operation
    });
    operation === 'BLOCK'
      ? (this.blockSimModal = false)
      : (this.unblockSimModal = false);
  }

  openBlockUnblockSimModal(event) {
    event.operation === 'BLOCK'
      ? (this.blockSimModal = true)
      : (this.unblockSimModal = true);
    this.subscriptionToChange = event.subscription;
  }

  ngOnInit(): void {}
}

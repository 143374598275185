import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Injectable()
export class TokenCheckResolve implements Resolve<string> {
  constructor(
    private router: Router,
    private api: ApiService,
    private lang: LanguageService
  ) {}

  resolve(): any {
    if (!this.api.token) {
      this.router.navigate([this.lang.current], { replaceUrl: true });
    }
  }
}

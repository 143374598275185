<div class="page-wrapper container-fluid dashboard-container">
  <notifier-container></notifier-container>
  <div class="buttons-container">
    <a routerLink="/{{ lang.current }}" class="return"
      ><img src="/assets/new/chevron_return.svg" />{{
        "WEBSITE" | translate
      }}</a
    >
    <a (click)="signOut()" class="return"
      >{{ "SIGN_OUT_BTN" | translate }}
      <img class="reverse" src="/assets/new/chevron_return.svg"
    /></a>
  </div>
  <div *ngIf="!loading">
    <app-suspended-account
      *ngIf="suspendedAccount; else notSuspended"
      [lang]="lang"
      [suspensionDate]="suspensionDate"
    ></app-suspended-account>
  </div>
</div>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>

<ng-template #notSuspended>
  <div class="content-container">
    <h2 class="content-title">{{ "HELLO" | translate }} {{ userEmail }}!</h2>
    <div class="content-loading" *ngIf="userIsOnboarded === 'loading'; else subscriptions">
      <div class="ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #subscriptions>
  <ng-container *ngIf="userIsOnboarded === true">
    <p *ngIf="showSubscriptions" class="content-description">
      {{ "DASHBOARD_DESCRIPTION" | translate }}
    </p>
    <app-user-subscriptions
      *ngIf="showSubscriptions"
      [userSubscriptions]="userSubscriptions"
      (simNumberToBlockUnblock)="blockUnblockSim($event)"
    ></app-user-subscriptions>
    <button class="sign-in-button" (click)="addSubscriptionModal = true"       *ngIf="showSubscriptions">
      {{ "ADD_SUBSCRIPTION" | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="userIsOnboarded === false">
    <p class="content-description">
      {{ "MORE_SUBSCRIPTIONS" | translate }}
    </p>
    <div class="qr-code">
      <img src="/assets/new/qr_code.png" alt="qr code" />
    </div>
    <app-store-buttons></app-store-buttons>
  </ng-container>
</ng-template>

<app-modal
  *ngIf="addSubscriptionModal"
  (isModalOpen)="hideSubscriptionModal($event)"
>
  <img class="modal-icon" src="/assets/new/warning_gray.svg" />
  <p class="modal-title">{{ "ADDITIONAL_SUBSCRIPTION" | translate }}</p>
  <p class="modal-description">{{ "MORE_SUBSCRIPTIONS" | translate }}</p>
  <button class="sign-in-button long" (click)="addSubscriptionModal = false">
    Ok
  </button>
</app-modal>
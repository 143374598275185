export interface CreateUserBody {
  /**
   * Cognito id
   */
  id: string;
  /**
   * Email address
   */
  email: string;
  languageKillbill?: CreateUserBody.LanguageKillbillEnum;
  /**
   * Login method
   */
  domain?: CreateUserBody.DomainEnum;
}
export namespace CreateUserBody {
  export type LanguageKillbillEnum = 'de_CH' | 'en_US' | 'fr_CH' | 'it_CH';
  export const LanguageKillbillEnum = {
    DeCH: 'de_CH' as LanguageKillbillEnum,
    EnUS: 'en_US' as LanguageKillbillEnum,
    FrCH: 'fr_CH' as LanguageKillbillEnum,
    ItCH: 'it_CH' as LanguageKillbillEnum
  };
  export type DomainEnum = 'apple' | 'google' | 'facebook' | 'email';
  export const DomainEnum = {
    Apple: 'apple' as DomainEnum,
    Google: 'google' as DomainEnum,
    Facebook: 'facebook' as DomainEnum,
    Email: 'email' as DomainEnum
  };
}

import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss']
})
export class HelpButtonComponent implements OnInit {
  currentLanguage: string;
  supportLink: string;

  constructor(public lang: LanguageService) {
    this.currentLanguage = this.lang.current;
    if (window.location.pathname.includes('/thank-you')) {
      this.supportLink = `https://support.swype.ch/hc/${this.currentLanguage}/articles/360003862298-How-can-I-activate-my-SIM-card-`;
    } else {
      this.supportLink = `https://support.swype.ch/hc/${this.currentLanguage}`;
    }
  }

  ngOnInit(): void {}
}

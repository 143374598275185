import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-animated-sim',
  templateUrl: './animated-sim.component.html',
  styleUrls: ['./animated-sim.component.scss']
})
export class AnimatedSimComponent {
  @Input() imgSrc: string;
  simShown = false;

  constructor() {
    this.simShown = false;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../language.service';
import { TariffItem } from '../slices/tariffs-slice';

@Component({
  selector: 'app-tariff-item',
  templateUrl: './tariff-item.component.html',
  styleUrls: ['./tariff-item.component.scss']
})
export class TariffItemComponent implements OnInit {
  @Input() tariffToDisplay: 'surf' | 'swiss' | 'europe' | 'custom';
  @Input() customTariff: TariffItem | null;
  @Input() classShort: string;
  tariff: TariffItem;

  constructor(private router: Router, public lang: LanguageService) {}

  ngOnInit() {
    switch (this.tariffToDisplay) {
      case 'surf':
        return (this.tariff = {
          tariff_name: 'Surf',
          tariff_price: '14.95',
          internet_package: 'SURF_INTERNET_OPTION',
          internet_details: 'SURF_INTERNET_DESCRIPTION',
          internet_icon: '../../assets/new/internet-package.svg',
          button_text: 'SURF_BUTTON'
        });
      case 'swiss':
        return (this.tariff = {
          tariff_name: 'Swiss',
          tariff_price: '19.95',
          internet_package: 'SWISS_INTERNET_OPTION',
          internet_details: 'SWISS_INTERNET_DESCRIPTION',
          internet_icon: '../../assets/new/internet-package.svg',
          calling_package: 'SWISS_CALLING_OPTION',
          calling_details: 'SWISS_CALLING_DESCRIPTION',
          calling_icon: '../../assets/new/phone-package.svg',
          button_text: 'SWISS_BUTTON'
        });
      case 'europe':
        return (this.tariff = {
          tariff_name: 'Europe',
          tariff_price: '34.95',
          internet_package: 'EUROPE_INTERNET_OPTION',
          internet_details: 'EUROPE_INTERNET_DESCRIPTION',
          internet_icon: '../../assets/new/internet-package.svg',
          calling_package: 'EUROPE_CALLING_OPTION',
          calling_details: 'EUROPE_CALLING_DESCRIPTION',
          calling_icon: '../../assets/new/phone-package.svg',
          roaming_package: 'EUROPE_ROAMING_OPTION',
          roaming_details: 'EUROPE_ROAMING_DESCRIPTION',
          roaming_icon: '../../assets/new/roaming-package.svg',
          button_text: 'EUROPE_BUTTON'
        });
      case 'custom':
        return (this.tariff = this.customTariff);
    }
  }

  redirectToTariffPage(): void {
    if (this.tariffToDisplay !== 'custom') {
      this.router.navigate([
        '/' +
          this.lang.current +
          '/swype-' +
          this.tariff.tariff_name.toLowerCase()
      ]);
    } else {
      this.router.navigate([
        '/' +
          this.lang.current +
          '/' +
          this.tariff.tariff_name.toLowerCase().trim()
      ]);
    }
  }
}

<div class="new-social-login">
  <div class="buttons-container">
    <button (click)="loginUsingFacebook($event)">
      <img src="/assets/new/facebook.svg" alt="facebook logo" />
    </button>
    <button (click)="loginUsingGoogle($event)">
      <img src="/assets/new/google.svg" alt="google logo" />
    </button>
  </div>
  <app-or-divider></app-or-divider>
</div>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>
<notifier-container></notifier-container>
<div class="subscription-container">
  <ng-container *ngFor="let subscription of userSubscriptions">
    <app-subscription-item
      [subscription]="subscription"
      (simOperation)="openBlockUnblockSimModal($event)"
    ></app-subscription-item>
  </ng-container>
</div>

<app-modal *ngIf="blockSimModal" (isModalOpen)="hideSimModal($event)">
  <img class="modal-icon" src="/assets/new/warning_red.svg" />
  <p class="modal-title">{{ "ARE_YOU_SURE" | translate }}</p>
  <p class="modal-description">{{ "BLOCK_SIM_WARNING" | translate }}</p>
  <div class="checkbox-container">
    <input
      type="checkbox"
      id="block"
      name="block"
      [checked]="blockConfirmation"
      (change)="blockConfirmation = !blockConfirmation"
    />
    <span class="info">{{ "I_WANT_BLOCK_SIM" | translate }}</span>
  </div>
  <div class="buttons-container">
    <button class="transparent-button" (click)="blockSimModal = false">
      {{ "CANCEL" | translate }}
    </button>
    <button
      [disabled]="!blockConfirmation"
      class="sign-in-button short"
      (click)="blockUnblockSim('BLOCK')"
    >
      {{ "BLOCK" | translate }}
    </button>
  </div>
</app-modal>

<app-modal *ngIf="unblockSimModal" (isModalOpen)="hideSimModal($event)">
  <img class="modal-icon" src="/assets/new/warning_red.svg" />
  <p class="modal-title">{{ "ARE_YOU_SURE" | translate }}</p>
  <p class="modal-description">{{ "UNBLOCK_SIM_WARNING" | translate }}</p>
  <div class="buttons-container unblock">
    <button class="transparent-button" (click)="unblockSimModal = false">
      {{ "CANCEL" | translate }}
    </button>
    <button class="sign-in-button short" (click)="blockUnblockSim('UNBLOCK')">
      {{ "UNBLOCK" | translate }}
    </button>
  </div>
</app-modal>

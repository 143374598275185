import { Component, Input, OnInit } from '@angular/core';
import { TariffHeaderSlice } from '../slices/tariff-header-slice';

@Component({
  selector: 'app-tariff-header',
  templateUrl: './tariff-header.component.html',
  styleUrls: ['./tariff-header.component.scss', '../intro/intro.component.scss']
})
export class TariffHeaderComponent implements OnInit {
  @Input() sliceData: TariffHeaderSlice;
  isMobileDevice = false;

  constructor() {}

  ngOnInit(): void {
    this.checkForMobileDevice();
  }

  checkForMobileDevice = () => {
    if (window.screen.width < 768) {
      this.isMobileDevice = true;
    }
  };
}

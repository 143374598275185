import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class OpenAppStoreService {
  constructor(private tracking: TrackingService) {}

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      const link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }
}

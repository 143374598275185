import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ForgotPassword } from '../DTO/ForgotPassword';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from '../email.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-check-your-email',
  templateUrl: './check-your-email.component.html',
  styleUrls: [
    './check-your-email.component.scss',
    '../email-verification/email-verification.component.scss'
  ]
})
export class CheckYourEmailComponent implements OnInit, OnDestroy {
  myForm: UntypedFormGroup;
  value: string;
  valid: boolean;
  code: string;
  email: string;
  codeInputBlocked: boolean;
  emailPresent: boolean;
  loading = false;
  emailSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private translate: TranslateService,
    private emailService: EmailService
  ) {}

  model = new ForgotPassword('');

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  ngOnInit(): void {
    this.emailPresent = false;
    this.code = this.route.snapshot.params[`code`];

    if (!this.code) {
      this.code = '';
    }

    this.emailSubscription = this.emailService.email$.subscribe(email => {
      (this.email = email), (this.emailPresent = true);
    });
  }

  goToForgotPassword() {
    const lang = this.route.snapshot.params[`lang`];
    this.router.navigate(['/' + lang + '/forgot-password']);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidity() {
    this.valid = this.validateEmail(this.email);
  }

  onPaste(event: ClipboardEvent, field) {
    this[field] = event.clipboardData.getData('text');
    this.checkValidity();
  }

  onEmailInput(event) {
    const value = event.target.value;
    this.email = value;
    this.checkValidity();
  }

  onPasteCode(event) {
    const code = event.clipboardData.getData('text');
    let element = event.srcElement;

    for (let i = 0; i < code.length; i++) {
      element.value = code[i];
      element = element.nextElementSibling;
    }

    this.valid = true;
    setTimeout(() => {
      this.code = code;
      this.resetPassword();
    }, 500);
    return false;
  }

  async resetPassword() {
    this.loading = true;
    this.submit(this.code);
    this.loading = false;
    this.showNotification(
      'success',
      this.translate.instant('REDIRECTING_TO_PASSWORD_RESET')
    );
    setTimeout(() => {
      const lang = this.route.snapshot.params[`lang`];
      this.router.navigate(['/' + lang + '/renew-password']);
    }, 1000);
  }

  isNumber(value) {
    return /^-?\d+$/.test(value) && value.length === 1;
  }

  async onDigitInput(event, isLastElement) {
    let element = event.srcElement;
    if (this.isNumber(event.key)) {
      element.value = event.key;
    }

    if (event.code === 'Backspace') {
      element = event.srcElement.previousElementSibling;
      element.focus();
    }

    if (isLastElement) {
      this.code = '';
      while (element.previousElementSibling !== null) {
        element = element.previousElementSibling;
      }

      for (let i = 0; i < 6; i++) {
        this.code = this.code + element.value;
        element = element.nextElementSibling;
      }

      if (this.code.length === 6) {
        this.resetPassword();
      }
    } else {
      if (event.code !== 'Backspace')
        element = event.srcElement.nextElementSibling;

      element.focus();
    }
  }

  submit(code) {
    localStorage.setItem('email', this.email);
    localStorage.setItem('code', code);
    return;
  }

  ngOnDestroy(): void {
    this.emailSubscription.unsubscribe();
  }
}

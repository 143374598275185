import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss']
})
export class SubscriptionItemComponent {
  @Input() subscription: any;
  @Output() simOperation = new EventEmitter<any>();

  setSimToBlockUnblockOperation(subscription: any, operation: string) {
    this.simOperation.emit({ subscription, operation });
  }
}

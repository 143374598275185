import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { throttle } from './libs/throttle';

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements OnDestroy, OnInit {
  @Output() enterView = new EventEmitter();
  @Output() leaveView = new EventEmitter();

  inView: boolean;
  onScroll: any;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.onScroll = this.testInView.bind(this);
    document.addEventListener('scroll', throttle(this.onScroll, 100));
    this.testInView();
  }

  testInView() {
    const bounds = this.elRef.nativeElement.getBoundingClientRect();
    const top = bounds.top - window.innerHeight + bounds.height;
    this.inView = top <= 0 && top > -window.innerHeight;
    const enteredView = top <= 0 && top > -window.innerHeight;
    const leftView = !(top - bounds.height <= 0 && top > -window.innerHeight);
    if (enteredView) {
      setTimeout(() => this.enterView.emit());
    } else if (leftView) {
      setTimeout(() => this.leaveView.emit());
    }
  }

  ngOnDestroy() {
    document.removeEventListener('scroll', this.onScroll);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TariffsSlice } from '../slices/tariffs-slice';

@Component({
  selector: 'app-tariffs',
  templateUrl: './tariffs.component.html',
  styleUrls: ['./tariffs.component.scss']
})
export class TariffsComponent implements OnInit {
  @Input() sliceData: TariffsSlice;
  tariffsToShow: string[] = [];
  isMobileDevice = false;
  currentSlide: number;
  position: number;
  constructor() {}

  checkForMobileDevice = () => {
    if (window.screen.width < 700) {
      this.isMobileDevice = true;
    }
  };

  showSlide(slideIndex: number): void {
    this.currentSlide = slideIndex;
  }

  ngOnInit(): void {
    this.checkForMobileDevice();

    if (this.sliceData.show_surf_tariff) {
      this.tariffsToShow.push('surf');
    }
    if (this.sliceData.show_swiss_tariff) {
      this.tariffsToShow.push('swiss');
    }
    if (this.sliceData.show_europe_tariff) {
      this.tariffsToShow.push('europe');
    }
    if (this.sliceData.show_custom_tariff) {
      for (const item of this.sliceData.items) {
        this.tariffsToShow.push('custom');
      }
    }

    switch (this.tariffsToShow.length) {
      case 3:
        this.position = 16.676;
        this.currentSlide = 1;
        break;
      case 2:
        this.position = 25;
        this.currentSlide = 0;
        break;
      case 1:
        this.position = 50;
        this.currentSlide = 0;
        break;
      default:
        this.position = 16.676;
        this.currentSlide = 1;
    }
  }
}

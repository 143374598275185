import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  public data: BehaviorSubject<{
    innerWidth: number;
    innerHeight: number;
    isDesktop: boolean;
  }>;

  constructor() {
    this.data = new BehaviorSubject({
      innerWidth: 0,
      innerHeight: 0,
      isDesktop: false
    });
  }

  onResize(innerWidth: number, innerHeight: number) {
    const isDesktop = this.isDesktop(innerWidth);
    this.data.next({ innerWidth, innerHeight, isDesktop });
  }

  private isDesktop(width: number) {
    return width > 700;
  }
}

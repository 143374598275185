<div
  class="slice tariff-slice"
  id="new-intro"
  [style.background-image]="'url(' + sliceData.backgroundImage + ')'"
>
  <div class="content center-content">
    <div class="intro-container content-splitter reversed">
      <div
        class="split-content title-container align-content-vertically-at-start"
      >
        <h1 class="page-title">{{ sliceData.title }}</h1>
        <p class="description">
          <span>{{ sliceData.description }}</span>
        </p>
        <div class="home-points" *ngIf="sliceData.show_features">
          <div class="point" *ngFor="let feature of sliceData.features">
            <img src="/assets/new/homepoint.svg" alt="" />
            <span>{{ feature }}</span>
          </div>
        </div>
        <app-sign-up-button
          location="tariff banner"
          [tariffName]="sliceData.title"
          *ngIf="!isMobileDevice"
          >{{ sliceData.button_text }}</app-sign-up-button
        >
      </div>
      <div
        class="split-content image-container align-content-vertically-at-start"
      >
        <div class="background">
          <img [src]="sliceData.backgroundImage" alt="" />
        </div>
        <div class="tariff">
          <app-tariff-item
            [tariffToDisplay]="sliceData.tariff_to_show"
            [customTariff]="sliceData.items[0]"
            classShort="true"
          ></app-tariff-item>
        </div>
      </div>
    </div>
  </div>
</div>

<span class="text-field-container">
  <img
    (click)="onToggleShowPassword()"
    [ngClass]="{ inactive: type === 'password' }"
    *ngIf="isPasswordType"
    src="/assets/icons/eye.svg"
    class="icon"
    alt=""
  />
  <label class="{{ labelClass }}">
    <span *ngIf="labelText">{{ labelText }}</span
    ><span *ngIf="required" class="required"> *</span>
    <input
      autocomplete="new-password"
      (keyup)="onChange($event)"
      (focus)="onFocus()"
      (focusout)="onFocusOut()"
      class="text-field"
      [ngClass]="{
        focused: focused,
        disabled: disabled,
        error: error,
        authentication: authentication
      }"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [type]="type"
      [attr.list]="list"
    />
  </label>
</span>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AddSwypeLead } from './interfaces/add-swype-lead';
import { UpdateSwypeLead } from './interfaces/update-swype-lead';
import { LanguageService } from './language.service';
import { StorageService } from './storage.service';
import { StorageEntryConfig } from './storage-entry-config.class';
import { CustomerAddress, CustomerData } from './customer-data';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public readonly ERR_ORDER_ALREADY_EXISTS = 'ERR_ORDER_ALREADY_EXISTS';
  public token: string;
  private options = { headers: { 'Content-Type': 'text/plain' } };
  public hasAddressDetails: boolean;
  public emailFound: boolean;
  public type = '';
  public customerData: CustomerData;

  constructor(
    private http: HttpClient,
    private lang: LanguageService,
    private storage: StorageService
  ) {}

  public submitEmail(data: CustomerData): Promise<Array<boolean>> {
    this.type = this.storage.get(StorageEntryConfig.UTM_CAMPAIGN) || '';

    // if we send the order data in two steps (add lead, then update lead),
    // we use 'orderStep' field to tell backend there's more data coming
    const orderStep = data.address ? 'COMPLETE' : 'PARTIAL';

    const params = {
      email: data.email,
      firstName: data.address?.firstName,
      lastName: data.address?.lastName,
      city: data.address?.city,
      zip: data.address?.zip,
      street: data.address?.street,
      streetNumber: data.address?.streetNumber,
      type: this.type,
      language: this.lang.current,
      orderStep
    };
    return this.http
      .post(`${environment.EndPointBase}/add_swype_lead`, params, this.options)
      .toPromise()
      .then((resp: AddSwypeLead) => {
        if (resp && resp.status === 1) {
          this.token = resp.data.token;
          this.customerData = data;
          // TODO: use these fields to inform the user about something?
          this.hasAddressDetails = resp.data.hasAddressDetails;
          this.emailFound = resp.data.emailFound;

          return [true, this.hasAddressDetails, this.emailFound];
        }
        if (resp && resp.errorCode === 'ERR_ORDER_ALREADY_EXISTS') {
          // Ignore it, because we only worry about the physical address
          return [true];
        } else {
          throw resp.errorCode;
        }
      });
  }

  public submitAddress(address: CustomerAddress): Promise<boolean> {
    if (!this.token) {
      throw 'no token';
    }
    this.type = this.storage.get(StorageEntryConfig.UTM_CAMPAIGN) || '';
    const params = {
      firstName: address.firstName,
      lastName: address.lastName,
      city: address.city,
      zip: address.zip,
      street: address.street,
      streetNumber: address.streetNumber,
      token: this.token,
      type: this.type,
      language: this.lang.current,
      orderStep: 'COMPLETE'
    };
    return this.http
      .post(
        `${environment.EndPointBase}/update_swype_lead`,
        params,
        this.options
      )
      .toPromise()
      .then((resp: UpdateSwypeLead) => {
        if (this.customerData) {
          this.customerData.address = address;
        } else {
          this.customerData = { email: '', address };
        }
        if (resp && resp.status === 1) {
          delete this.token; // backend doesn't currently allow further updates
          this.customerData.validationCode = '';
          return true;
        } else if (resp && resp.errorCode === this.ERR_ORDER_ALREADY_EXISTS) {
          // We have a rule in the backend that one can order max 2 times within 90 days window (based on the name + address)
          console.log(resp.errorCode);
          this.customerData.validationCode = this.ERR_ORDER_ALREADY_EXISTS;
          return true;
        } else {
          console.log(resp.errorCode);
          this.customerData.validationCode = resp.errorCode;
          throw resp.errorCode;
        }
      });
  }

  /**
   * Send an sms with the download link for the Swype app
   * to the given msisdn. Msisdn needs to be a Swiss mobile phone
   * number (07...)
   * @return true if sending was successful, false otherwise
   */
  public sendDownloadLink(msisdn: string): Promise<boolean> {
    if (new RegExp(/0?7(\d+)/).test(msisdn)) {
      const params = {
        method: 'SMS',
        methodValue: msisdn,
        language: this.lang.current
      };
      return this.http
        .post(
          `${environment.EndPointBase}/send_swype_adl`,
          params,
          this.options
        )
        .toPromise()
        .then((resp: { status: number; data: {} }) => {
          return resp && resp.status === 1;
        });
    }
    return Promise.resolve(false);
  }
}

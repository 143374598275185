<div class="slice" id="image_with_content">
  <div class="content">
    <div class="image-container">
      <img
        *ngIf="sliceData.img"
        class="main-image"
        [src]="sliceData.img"
        alt="background image"
      />
      <img
        *ngIf="sliceData.icon"
        class="icon"
        [src]="sliceData.icon"
        alt="icon"
      />
    </div>
    <div class="text-container">
      <h2 class="content-title">{{ sliceData.title }}</h2>
      <p class="content-description">{{ sliceData.description }}</p>
    </div>
    <div *ngIf="sliceData.show_items" class="items-container">
      <div class="item" *ngFor="let item of sliceData.items">
        <img class="check" src="/assets/new/homepoint.svg" alt="" />{{
          item.text | titlecase
        }}
      </div>
    </div>
    <app-sign-up-button *ngIf="sliceData.show_button">{{
      "GET_SWYPE" | translate
    }}</app-sign-up-button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private email = new BehaviorSubject<any>({});
  public email$: Observable<any> = this.email.asObservable();

  constructor() {}

  public sendEmail(email: string): void {
    this.email.next(email);
  }
}

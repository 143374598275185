import { environment } from './environments/environment';

export const awsExports = environment.production
  ? {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'eu-central-1:0273ab90-7971-4d6e-9e94-1123abc947c7',

      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-central-1_874Wbxme6',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '4f33ttsjqvfe1tiqo26dtav82n',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      //authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'swype.auth.eu-central-1.amazoncognito.com',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: 'swype://callback',
        redirectSignOut: 'swype://signout',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  }

  : (environment.staging ? {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "eu-central-1:e74dff71-562c-4b53-873d-5a55d7e28e83",

      // REQUIRED - Amazon Cognito Region
      region: 'eu-central-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-central-1_G4bSbZvao',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '5n3cfqtch2ti93fb5jcqekuk64',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      //authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: 'swype-stage.auth.eu-central-1.amazoncognito.com',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin'
        ],
        redirectSignIn: 'https://new-layout.d5k1k0eyh4e87.amplifyapp.com/',
        redirectSignOut: 'https://new-layout.d5k1k0eyh4e87.amplifyapp.com/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  }
    : {
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-central-1:b92bf465-0194-46a5-b306-6cbd7df8616e',

        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_zzarciQLI',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '65gug8ivipmplvcq8l6sppv6u9',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        //authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'swype-dev.auth.eu-central-1.amazoncognito.com',
          scope: [
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: 'https://www.dashboard.dev.swype.ch/',
          redirectSignOut: 'https://www.dashboard.dev.swype.ch/',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  );

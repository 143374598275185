import { Component, Input, OnInit } from '@angular/core';
import { PasswordRequirements } from '../requirements.interface';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss']
})
export class PasswordRequirementsComponent implements OnInit {
  @Input() requirements: PasswordRequirements;

  constructor() {}

  ngOnInit(): void {}
}

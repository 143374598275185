<div class="store-buttons-container">
  <img
    (click)="storeService.openAppStore('apple')"
    src="/assets/new/app_store.png"
    alt=""
    class="app-store"
  />
  <img
    (click)="storeService.openAppStore('google')"
    src="/assets/new/google_play.png"
    alt=""
    class="google-play"
  />
</div>

import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss']
})
export class ButtonContainerComponent implements OnInit {
  supportLink: string;

  constructor(public lang: LanguageService) {}

  ngOnInit(): void {
    window.location.pathname === '/en'
      ? (this.supportLink = `https://support.swype.ch/hc/en-gb`)
      : (this.supportLink = `https://support.swype.ch/hc/${this.lang.current}`);
  }
}

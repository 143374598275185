<div class="page-wrapper">
  <app-header [currentSite]="currentPrismicSite"></app-header>

  <div
    class="page main"
    [ngClass]="currentPrismicSite === 'terms' ? 'white' : ''"
  >
    <div class="page-content">
      <div *ngFor="let item of slices">
        <app-intro
          *ngIf="item.type == 'new_intro'"
          [sliceData]="item"
        ></app-intro>
        <app-tariffs
          *ngIf="item.type == 'tariffs'"
          [sliceData]="item"
        ></app-tariffs>
        <app-features
          *ngIf="item.type == 'new_features'"
          [sliceData]="item"
        ></app-features>
        <app-four-items
          *ngIf="item.type == 'four_items'"
          [sliceData]="item"
        ></app-four-items>
        <app-faqs *ngIf="item.type == 'faqs'" [sliceData]="item"></app-faqs>
        <app-get-the-app
          *ngIf="item.type == 'get_the_app'"
          [sliceData]="item"
        ></app-get-the-app>
        <app-tariff-header
          *ngIf="item.type == 'tariff_header'"
          [sliceData]="item"
        ></app-tariff-header>
        <app-image-with-content
          *ngIf="item.type == 'image_with_content'"
          [sliceData]="item"
        ></app-image-with-content>
        <app-tutorial *ngIf="item.type == 'tutorial_steps'" [sliceData]="item">
        </app-tutorial>
        <app-custom-content
          *ngIf="item.type == 'custom_content'"
          [sliceData]="item"
        >
        </app-custom-content>
        <app-image-with-cta
          *ngIf="item.type == 'image_with_cta'"
          [sliceData]="item"
        ></app-image-with-cta>
        <app-video *ngIf="item.type == 'video'" [sliceData]="item"></app-video>
        <app-show-url-parameter-slice
          *ngIf="item.type == 'show-url-parameter'"
          [sliceData]="item"
        >
        </app-show-url-parameter-slice>
        <app-terms *ngIf="item.type == 'terms'" [sliceData]="item"> </app-terms>
      </div>
    </div>
  </div>

  <div class="page no-gradient">
    <app-footer></app-footer>
  </div>
</div>
